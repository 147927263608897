<template>
  <div>
    <!--table dashUser-->
    <ek-table
      :columns="columns"
      :items="dashList"
      @delete-selected="deleteUsers"
      @details="detailsAppPanel"
    >
      <template slot="items.dateCreated" slot-scope="{ value }">
        <span> {{ new Date(value).toLocaleDateString() }}</span>
      </template>
      <template slot="items.isBlocked" slot-scope="{ value }">
        <StatusBadge :value="value" :options="dashUserStatusList" />
      </template>
    </ek-table>
  </div>
</template>

<script>
import { mapState, mapActions,mapGetters } from "vuex";
import StatusBadge from "@global/components/StatusBadge.vue";
export default {
  components: {
    StatusBadge,
  },
  computed: {
    ...mapState({
      columns: (state) => state.dashboardUsers.columns,
      dashUserList: (state) => state.dashboardUsers.dashUserList,
      dashUserStatusList: (state) => state.dashboardUsers.dashUserStatus,
    }),
    ...mapGetters(["dashList"]),
  },
  methods: {
    ...mapActions(["getGetUnRead"]),
    detailsAppPanel({ row }) {
      this.$store.commit("IS_DIALOG_OPEN", true);
      this.$store.commit("SET_USER_DTO", row);
    },
    deleteUsers(e) {
      this.$store.dispatch("deleteRangeDashUser", e);
    },
  },
  created() {
    this.$store.dispatch("getDashUser");
    this.getGetUnRead();
  },
};
</script>

<style></style>
